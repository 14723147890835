<template>
  <div v-if="!hideHeader" class="public-header">
    <div class="public-header-fixed">
      <div class="public-wrapper header-wrapper">
        <!-- Logo -->
        <div class="logo-wrapper" @click="goHome">
          <span class="logo">
            <img src="@/assets/images/logo.png" alt="达诺学苑logo" />
          </span>
          <span>达诺学苑</span>
        </div>

        <!-- PC端菜单 -->
        <div class="menu-wrapper">
          <div class="menu-list">
            <div v-for="item in menuList" :key="item.name" class="menu-item">
              <div
                :class="['menu-link', getCurrentActive(item) ? 'active' : '']"
                @click="onMenuClick(item)"
              >
                <span class="menu-text">{{ item.name }}</span>
                <el-icon v-if="item.children?.length" class="menu-icon">
                  <ArrowDownBold />
                </el-icon>
              </div>
              <template v-if="item.children?.length">
                <div class="sub-menu">
                  <div class="sub-menu-list">
                    <div
                      v-for="subItem in item.children"
                      :key="subItem.name"
                      class="sub-menu-item"
                      @click="onMenuClick(subItem)"
                    >
                      {{ subItem.name }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- PC端右侧登录区域 -->
        <div class="right-wrapper">
          <!-- 移动端菜单图标 -->
          <div class="mobile-menu-icon" @click="toggleMobileMenu">
            <el-icon :size="24">
              <Menu />
            </el-icon>
          </div>
          <div v-if="!userStore.isLogin" class="login-btns">
            <a @click="onLogin">登录</a>
            <span>|</span>
            <a @click="onRegister">注册</a>
          </div>
          <div v-else class="personal-info">
            <div class="user-avatar">{{ userName }}</div>
            <div class="personal-menu">
              <div class="menu-list">
                <div class="menu-item" data-path="/dashboard" @click="goDashboard">
                  个人中心
                </div>
                <div class="menu-item" @click="onLogout">退出</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 移动端侧边菜单 -->
        <div class="mobile-side-menu" :class="{ active: isMobileMenuOpen }">
          <div class="menu-content">
            <!-- 登录状态显示用户信息 -->
            <template v-if="userStore.isLogin">
              <div class="user-info">
                <div class="avatar">{{ userName }}</div>
              </div>
              <div class="divider"></div>
            </template>
            
            <!-- 菜单列表 -->
            <div
              v-for="item in mobileMenuList"
              :key="item.name"
              class="mobile-menu-item"
            >
              <div class="menu-title" @click="handleMobileMenuClick(item)">
                <span>{{ item.name }}</span>
                <el-icon v-if="item.children?.length" :class="{ 'is-expanded': expandedMenus.has(item.name) }">
                  <ArrowDownBold />
                </el-icon>
              </div>
              <div v-if="item.children?.length" 
                   class="sub-items"
                   :class="{ 'expanded': expandedMenus.has(item.name) }">
                <div
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  class="sub-item"
                  @click.stop="onMenuClick(subItem)"
                >
                  {{ subItem.name }}
                </div>
              </div>
            </div>

            <!-- 用户相关菜单 -->
            <template v-if="userStore.isLogin">
              <div class="divider"></div>
              <div class="mobile-menu-item" @click="goDashboard">
                <div class="menu-title">
                  <span>个人中心</span>
                </div>
              </div>
              <div class="mobile-menu-item" @click="onLogout">
                <div class="menu-title">
                  <span>退出登录</span>
                </div>
              </div>
            </template>
            
            <!-- 未登录状态显示登录注册 -->
            <template v-else>
              <div class="divider"></div>
              <div class="mobile-menu-item" @click="onLogin">
                <div class="menu-title">
                  <span>登录</span>
                </div>
              </div>
              <div class="mobile-menu-item" @click="onRegister">
                <div class="menu-title">
                  <span>注册</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ArrowDownBold, Menu } from '@element-plus/icons-vue';
import { useUserStore } from '@/stores/user';
import { removeToken } from '@/utils/auth';
import { session } from '@/utils/storage';
import { http } from '@/http/client';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const menuList = ref([
  {
    name: '首页',
    path: '/index',
  },
  {
    name: '成长学堂',
    children: [
      {
        name: '学习培训',
        path: '/study',
      },
      {
        name: '大咖视频',
        path: '/videos',
      },
    ],
  },
  {
    name: '合规智库',
    path: '/repository',
  },

  {
    name: '试题征集',
    path: '/question-collection',
  },
  {
    name: '能力验证',
    path: '/capability',
  },
  {
    name: '信息查询',
    path: '/info-search',
    children: [
      {
        name: 'FDA信号查询',
        path: '/info-search/fad-search',
      },
    ],
  },
  {
    name: '证书查询',
    path: '/certificate',
  },
  {
    name: '专业导航',
    path: '/professional-nav',
  },
  {
    name: '联系咨询',
    path: '/consult',
  },
  // {
  //   name: '合作伙伴',
  // },
  // {
  //   name: '关于我们',
  //   path: '/about',
  // },
]);

const userName = computed(() => {
  return (userStore.userInfo.userName || '').slice(0, 1) || '';
});

const isMobileMenuOpen = ref(false);
const expandedMenus = ref(new Set());  // 用于记录展开的菜单

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const onMenuClick = (item) => {
  if ((item.children && item.children.length) || !item.path) {
    return;
  }
  isMobileMenuOpen.value = false;
  router.push(item.path || '/');
};

const getCurrentActive = (item) => {
  // console.log(router.currentRoute.value.fullPath, item.path);
  return router.currentRoute.value.fullPath.includes(item.path);
};

const goHome = () => {
  router.push('/index');
};

const onLogin = () => {
  router.push('/login');
  isMobileMenuOpen.value = false;  // 关闭菜单
};

const onRegister = () => {
  router.push('/register');
  isMobileMenuOpen.value = false;  // 关闭菜单
};

const goDashboard = () => {
  router.push('/dashboard');
  isMobileMenuOpen.value = false;  // 关闭菜单
};

const onLogout = async () => {
  try {
    await http.post('/platform/web/user/logout');
    removeToken();
    session.remove('userInfo');
    isMobileMenuOpen.value = false;  // 关闭菜单
    window.location.href = '/';
  } catch (error) {
    console.error('退出登录失败:', error);
  }
};

const handleMobileMenuClick = (item) => {
  if (item.children?.length) {
    // 如果有子菜单，切换展开/收起状态
    const isExpanded = expandedMenus.value.has(item.name);
    isExpanded ? expandedMenus.value.delete(item.name) : expandedMenus.value.add(item.name);
  } else {
    // 如果没有子菜单，直接跳转
    onMenuClick(item);
  }
};

// 点击外部关闭菜单
onMounted(() => {
  const handleClickOutside = (e) => {
    const mobileMenu = document.querySelector('.mobile-menu');
    if (mobileMenu && !mobileMenu.contains(e.target)) {
      isMobileMenuOpen.value = false;
    }
  };
  
  document.addEventListener('click', handleClickOutside);
  
  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
});

// 添加移动端菜单列表计算属性
const mobileMenuList = computed(() => {
  return menuList.value.filter(item => 
    item.name !== '成长学堂' && item.name !== '试题征集'
  );
});

// 添加计算属性来判断是否隐藏header
const hideHeader = computed(() => {
  return route.query.hideHeader === 'true';
});
</script>

<style lang="less" scoped>
.public-header {
  height: 66px;
  position: relative;
  z-index: 999;
  
  .public-header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 66px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }

  .public-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  height: 66px;
  padding: 0 24px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }

  // Logo样式优化
  .logo-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    flex-shrink: 0;
  
    .logo {
      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }
    }
    
    span {
      margin-left: 10px;
      font-size: 24px;
      font-weight: 600;
      color: #1a1a1a;
    }

    @media screen and (max-width: 768px) {
      .logo {
        img {
          width: 28px;
          height: 28px;
        }
      }
      
      span {
        font-size: 18px;
        margin-left: 8px;
      }
    }
  }

  // 主菜单样式优化
  .menu-wrapper {
    flex: 1;
    margin-left: 48px;
    
    @media screen and (max-width: 768px) {
      display: none;
    }
    
    .menu-list {
      display: flex;
      align-items: center;
      gap: 12px;
      
      .menu-item {
        position: relative;
        
        .menu-link {
          padding: 8px 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          border-radius: 6px;
          transition: all 0.3s ease;
          
          .menu-text {
            font-size: 15px;
            font-weight: 500;
            color: #333;
            padding: 0 2px;
          }
          
          .menu-icon {
            margin-left: 4px;
            transition: all 0.3s;
            font-size: 12px;
            color: #666;
          }

          // 添加激活状态样式
          &.active {
            background-color: rgba(10, 71, 237, 0.04);
            
            .menu-text {
              color: #0a47ed;
            }
            
            .menu-icon {
              transform: rotate(180deg);
              color: #0a47ed;
            }
          }

          // 添加悬浮状态样式
          &:hover {
            background-color: rgba(10, 71, 237, 0.04);
            
            .menu-text {
              color: #0a47ed;
            }
            
            .menu-icon {
              transform: rotate(180deg);
              color: #0a47ed;
            }
          }
        }

        // 子菜单样式优化
        .sub-menu {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          padding-top: 8px;
          min-width: 140px;
          transition: all 0.2s ease-in-out;
          
          &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent white transparent;
            filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.03));
          }
          
          .sub-menu-list {
            background: white;
            border-radius: 8px;
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
            padding: 6px 0;
            text-align: center;
            
            .sub-menu-item {
              padding: 10px 24px;
              white-space: nowrap;
              cursor: pointer;
              color: #4a4a4a;
              font-size: 14px;
              transition: all 0.2s ease;
              line-height: 1.4;
              
              &:hover {
                color: #0a47ed;
                background-color: rgba(10, 71, 237, 0.04);
              }
            }
          }
        }

        &:hover {
          .sub-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  // 右侧登录区域样式优化
  .right-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    
    .mobile-menu-icon {
      display: none;
      cursor: pointer;
      padding: 8px;
      color: #333;
      
      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .login-btns {
      display: flex;
      align-items: center;
    }
    
    @media screen and (max-width: 768px) {
      .login-btns, .personal-info {
        display: none;
      }
    }
    
    // 个人信息区域
    .personal-info {
      position: relative;
      
      // 用户头像/姓氏样式
      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #e8f0fe;
        color: #0a47ed;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        transition: all 0.3s ease;
        border: 2px solid transparent;
        
        &:hover {
          background: #0a47ed;
          color: #fff;
          border-color: rgba(10, 71, 237, 0.2);
          transform: translateY(-1px);
        }
      }

      // 修改下拉菜单样式
      .personal-menu {
        display: none;
        position: absolute;
        top: calc(100% - 5px); // 减小顶部间距
        right: 50%;
        transform: translateX(50%);
        padding-top: 15px; // 增加内边距，确保覆盖间隙
        min-width: 120px;
        
        &::before {
          content: '';
          position: absolute;
          top: 8px; // 调整三角形位置
          left: 50%;
          transform: translateX(-50%);
          border-width: 7px;
          border-style: solid;
          border-color: transparent transparent white transparent;
          filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.03));
        }
        
        // 添加一个透明的覆盖区域，填充头像和菜单之间的间隙
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 15px; // 与 padding-top 相同
          background: transparent;
        }
        
        .menu-list {
          position: relative;
          background: white;
          border-radius: 8px;
          box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
          overflow: hidden;
          
          .menu-item {
            padding: 10px 24px;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            color: #666;
            font-size: 14px;
            transition: all 0.2s ease;
            
            &:hover {
              color: #0a47ed;
              background-color: rgba(10, 71, 237, 0.04);
            }

            &:not(:last-child) {
              border-bottom: 1px solid #f5f5f5;
            }
          }
        }
      }

      &:hover {
        .personal-menu {
          display: block;
        }
        
        .user-avatar {
          background: #0a47ed;
          color: #fff;
          border-color: rgba(10, 71, 237, 0.2);
        }
      }
    }

    .login-btns {
      display: flex;
      align-items: center;
      gap: 4px;
      
      a {
        color: #666;
        cursor: pointer;
        font-size: 14px;
        border-radius: 6px;
        transition: all 0.3s ease;
        
        &:hover {
          color: #0a47ed;
          background-color: rgba(10, 71, 237, 0.04);
        }
      }
      
      span {
        color: #e0e0e0;
        margin: 0 4px;
      }
    }
  }

  // 移动端侧边菜单
  .mobile-side-menu {
    position: fixed;
    top: 66px;
    right: 0;
    bottom: 0;
    width: 75%;
    max-width: 300px;
    background: #fff;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    overflow-y: auto;
    
    &.active {
      transform: translateX(0);
    }
    
    .menu-content {
      padding: 16px 0;
      
      .user-info {
        padding: 0 20px 16px;
        
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #e8f0fe;
          color: #0a47ed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 500;
        }
      }
      
      .divider {
        height: 1px;
        background: #f5f5f5;
        margin: 8px 0;
      }
      
      .mobile-menu-item {
        .menu-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 20px;
          font-size: 16px;
          color: #333;
          cursor: pointer;
          
          .el-icon {
            font-size: 12px;
            color: #666;
            transition: transform 0.3s;
            
            &.is-expanded {
              transform: rotate(180deg);
            }
          }
          
          &:active {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
        
        .sub-items {
          display: none;
          background: #f5f5f5;
          
          &.expanded {
            display: block;
          }
          
          .sub-item {
            padding: 12px 20px 12px 40px;
            font-size: 14px;
            color: #666;
            cursor: pointer;
            
            &:active {
              background-color: rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }
}
</style>
