import { createApp } from 'vue';
import { createPinia } from 'pinia';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import '@/assets/styles/reset.less';
import '@/assets/styles/commom.less';

import './permission';
import App from './App.vue';
import router from './router';

// 禁用右键和F12的功能
const disableDevTools = () => {
  if (!window.location.href.includes('?f12')) {
    // 禁用右键菜单
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    });

    // 禁用F12和开发者工具快捷键
    document.addEventListener('keydown', (e) => {
      if (
        e.key === 'F12' || 
        (e.ctrlKey && e.shiftKey && e.key === 'I') || 
        (e.ctrlKey && e.shiftKey && e.key === 'J') || 
        (e.ctrlKey && e.shiftKey && e.key === 'C') ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
        return false;
      }
    });
  }
};

disableDevTools();

const pinia = createPinia();
console.log('aaa');
const app = createApp(App);
app.use(pinia);

app.use(router);
app.use(ElementPlus);
app.mount('#pv-platform-app');
