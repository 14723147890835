<template>
  <div class="index-banner-wrapper">
    <div class="public-wrapper">
      <el-carousel :height="isMobile ? '200px' : '420px'">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img
            :style="{ width: '100%', height: '100%', objectFit: 'cover' }"
            :src="`${'/api/sci-vigi'}/support/file/preview/${item.fileId}`"
            alt=""
            class="banner-item-img"
            @click="goLink(item.linkUrl)"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script setup>
import { http } from '@/http/client';
import { onMounted, ref, computed, onUnmounted } from 'vue';

const bannerList = ref([]);
const windowWidth = ref(window.innerWidth);

const isMobile = computed(() => {
  return windowWidth.value <= 768;
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const getBannerList = async () => {
  try {
    const res = await http.get('/platform/web/carousel/getCarouselList');
    bannerList.value = res || [];
  } catch (error) {
    //
  }
};

const goLink = (link) => {
  if (!link) return;
  window.open(link, '_blank');
};

onMounted(() => {
  getBannerList();
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="less" scoped>
.index-banner-wrapper {
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  
  .public-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  :deep(.el-carousel) {
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0;
    }
  }

  :deep(.el-carousel__container) {
    width: 100%;
    overflow: hidden;
  }

  :deep(.el-carousel__item) {
    width: 100%;
    overflow: hidden;
  }

  :deep(.el-carousel__indicators) {
    @media screen and (max-width: 768px) {
      bottom: 8px;
      transform: scale(0.8);
    }
  }

  :deep(.el-carousel__button) {
    @media screen and (max-width: 768px) {
      width: 6px;
      height: 6px;
      border-radius: 3px;
    }
  }

  .banner-item-img {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    @media screen and (max-width: 768px) {
      object-position: center center;
    }
  }
}
</style>
