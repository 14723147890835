<template>
  <div v-if="!hideFooter" class="public-footer">
    <div class="public-wrapper footer-wrapper">
      <div class="copyright">
        <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024080227号</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

// 添加计算属性来判断是否隐藏footer
const hideFooter = computed(() => {
  return route.query.hideHeader === 'true';  // 使用相同的URL参数控制
});
</script>

<style lang="less" scoped>
.public-footer {
  background-color: #1d1d1f;
  padding: 24px 0;
  width: 100%;
  position: relative;
  z-index: 10;

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }

    .copyright {
      text-align: center;

      a {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        text-decoration: none;
        transition: color 0.3s ease;
        
        &:hover {
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
}
</style>
